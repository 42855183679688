import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/central.jpg';

function App() {
  return (
    <Container fluid className="mt-5" >
      <Row>
        <Col className="mt-5" sm md={{ span: 3, offset: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
          <div><h1 className="text-center" style={{fontSize: 56, fontWeight: 'bold', letterSpacing: 5, lineHeight: 0.9 }}>Beatriz Garcez</h1> <br/> <h1 className="text-center" style={{ fontSize: 54, letterSpacing: 2, lineHeight: 0.8}}>branding & design</h1></div>
        </Col>
          <Col className="mt-5" sm md={{ span: 3, offset: 1 }} style={{ display: 'flex', alignItems: 'center' }}>
            <img
              width={window.innerWidth <= 760 ? 320 : 600}
              src={logo}
              style={{ margin: 'auto' }}
              alt="foto conceitual de beatriz garcez"
            />
          </Col>
      </Row>
    </Container >
  );
}

export default App;
