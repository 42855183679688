import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Container fluid className="mt-5" id="contact">
      <Row className="mt-4">
        <Col className="text-center"><a href="mailto:contato@beatrizgarcez.com"><h5 style={{ color: 'black', letterSpacing: 2, lineHeight: 0.5 }}>Para mais informações:</h5> <br/><h5 style={{ fontWeight: 'bold', color: 'black', lineHeight: 0.5 }}>contato@beatrizgarcez.com</h5></a></Col>
      </Row>
      
      <Row className="mt-4" style={{backgroundColor: '#000'}}>
        
        <Col className="text-center mt-3 mb-2"><h6 style={{ color: '#fff' }}>Copyright © 2021 Beatriz Garcez. Todos os Direitos Reservados</h6></Col>
      </Row>
    </Container>
  );
}

export default App;
