import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Container className="mb-5" fluid  style={{ backgroundColor: '#000' }}>
      {window.innerWidth <= 760 ? <a id="about"></a> : <a class="topdif" id="about"></a>}
      <Row>
        <Col className="mt-5 mb-5" sm md={{ span: 8, offset: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
          <p className="ml-5 mr-5" style={{ fontSize: 24, color: '#fffbfb', textAlign: 'center' }}><a style={{ fontWeight: 'bold' }}>Beatriz Garcez</a> idealizadora do <a style={{ fontWeight: 'bold' }}>@bcontent</a>. Cansada de ver empresas que não comunicam o seu propósito e identidades visuais que não estão alinhadas ao branding, decidiu usar sua formação em comunicação e multimeios na PUC-SP criando uma empresa que une todos esses aspectos trazendo uma identidade coesa.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
