import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Main from "./main"

const App = () => {
  return (
      <Routes>
        <Route exact={true} path="/" element={<Main />} />
      </Routes>
  );
}

export default App;