import { Container, Row, Col, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import mini1 from '../assets/mini1.png';
import mini2 from '../assets/mini2.png';
import mini3 from '../assets/mini3.png';

function App() {
  return (
    <Container fluid className="mt-5" id="content">
      <Row className="mr-2 ml-2" style={{ display: 'flex', justifyContent: 'center' }}>
        <Col sm md={{ span: 4 }} className="mt-5">
          <a href="https://instagram.com/bcontent_"><Image src={mini1} rounded fluid /></a>
        </Col>
        <Col sm md={{ span: 4 }} className="mt-5">
          <a href="https://instagram.com/bcontent_"><Image src={mini2} rounded fluid /></a>
        </Col>
        <Col sm md={{ span: 4 }} className="mt-5">
          <a href="https://instagram.com/bcontent_"><Image src={mini3} rounded fluid /></a>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
