import insta from '../assets/social-01.png';
import youtube from '../assets/social-03.png';
import { Nav, Navbar, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Container id="home">
      <Navbar collapseOnSelect style={{ backgroundColor: '#FFFFFF' }} expand="lg">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" style={{ display: 'flex', alignItems: 'center' }}>
          <Nav className="ml-5" style={{ height: '50px' }}>
            <Nav.Link href="#home" className="ml-2 mr-5"><h3 style={{ 'color': '#000', fontWeight: 'bold' }}>home</h3></Nav.Link>
            <Nav.Link href="#about" className="ml-2 mr-5"><h3 style={{ 'color': '#000', fontWeight: 'bold' }} >sobre</h3></Nav.Link>
            <Nav.Link href="#content" className="ml-2 mr-5"><h3 style={{ 'color': '#000', fontWeight: 'bold' }}>@bcontent</h3></Nav.Link>
            <Nav.Link href="#contact" className="ml-2 mr-5"><h3 style={{ 'color': '#000', fontWeight: 'bold' }}>contato</h3></Nav.Link>
            {window.innerWidth <= 760 ? <Nav.Link href="https://instagram.com/bcontent_" className="ml-2 mr-5">
              <img
                src={insta}
                height={33}
                className="d-inline-block align-top"
                alt="instagram"
              />
            </Nav.Link>
              : ""}
            {window.innerWidth <= 760 ? <Nav.Link href="http://linkedin.com/in/beatrizgarcez" className="ml-2 mr-5">
              <img
                src={youtube}
                height={33}
                className="d-inline-block align-top"
                alt="youtube"
              />
            </Nav.Link>
              : ""}
          </Nav>
          {window.innerWidth <= 760 ? "" : <div style={{ width: '30vw' }}></div>}
          {window.innerWidth <= 760 ? "" : <Nav className="ml-auto mr-5 d-inline-block">
            <a href="https://instagram.com/bcontent_" style={{ 'width': '50px', height: '50px', marginRight: 32 }}>
              <img
                src={insta}
                height={window.innerWidth <= 760 ? 25 : 33}
                className="d-inline-block align-top"
                alt="instagram"
              />
            </a>
            <a href="http://linkedin.com/in/beatrizgarcez" style={{ 'width': '50px', height: '50px' }}>
              <img
                src={youtube}
                height={window.innerWidth <= 760 ? 24 : 32}
                className="d-inline-block align-top ml-4"
                alt="youtube"
              />
            </a>
          </Nav>}

        </Navbar.Collapse>
      </Navbar>
    </Container >
  );
}

export default App;
